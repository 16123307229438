/* styles.css */
body {
  font-family: "Open Sans", sans-serif;
  background-color: #f0f0f0; /* Softer background */
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #333; /* Darker text for contrast */
}

.app-container {
  display: flex;
  flex-direction: column;
  flex: 1; /* Makes the container take up full height */
}

header {
  background-color: #333; /* Darker header */
  color: white;
  padding: 20px;
  text-align: center;
}

main {
  flex: 1; /* Main content takes up available space */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 50px;
}

.content-wrapper {
  background-color: white;
  padding: 40px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;
  max-width: 600px; /* Limit width on larger screens */
}

h1 {
  color: #007bff;
  font-size: 2.5rem; /* Slightly smaller heading */
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem; /* Slightly smaller paragraph font */
  margin-bottom: 20px;
  line-height: 1.6; /* Improved readability */
}

.headshot {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Smaller shadow */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.headshot:hover {
  transform: scale(1.05);
}

.linkedin-button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.linkedin-button:hover {
  background-color: #0056b3;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

footer a {
  color: #007bff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 30px;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .headshot {
    width: 120px;
    height: 120px;
  }
}
